<template>
  <div>
    <div class="bg-image">
      <img
        src="https://images.fineartamerica.com/images/artworkimages/mediumlarge/2/8-beer-coasters-background-benjamin-dupont.jpg"
        alt="achtergrond-bierviltjes"
      />
    </div>
    <!-- Submit a request -->
    <el-card v-if="allowedToSubmit && !justRequested" class="card card--small">
      <h1 class="card__heading">Stuur ons jouw verzoekje</h1>

      <p class="card__description">
        Tijdens de show pikt ons systeem er volledig willekeurig een aantal
        verzoekjes uit. Houd onze schermen in de gaten!
      </p>

      <el-form
        :model="form"
        label-position="top"
        :rules="formRules"
        size="small"
        :show-message="true"
        :hide-required-asterisk="true"
        :status-icon="false"
        ref="form"
        @submit.prevent="submitRequest"
      >
        <el-form-item label="Titel" prop="title">
          <el-input
            v-model="form.title"
            placeholder="Vul de titel van het nummer in"
            autofocus
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="Artiest" prop="artist">
          <el-input
            v-model="form.artist"
            placeholder="Vul de naam van de artiest in"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="Je voornaam" prop="firstName">
          <el-input
            v-model="form.firstName"
            placeholder="Vul je voornaam in"
            maxlength="12"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Je achternaam"
          prop="lastName"
          style="margin-bottom: 4rem"
        >
          <el-input
            v-model="form.lastName"
            placeholder="Vul je achternaam in"
            maxlength="12"
            show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-checkbox v-model="form.karaoke" :border="true"
            >Ik wil karaoke zingen</el-checkbox
          >
        </el-form-item>

        <el-form-item label="Foto">
          <div style="margin-bottom: 1rem">
            <p class="label-subtitle">
              Upload hier je foto en zie jezelf wellicht terug op onze schermen.
            </p>
            <p style="color: #cc0e22">
              Let op: deze foto kan ook op social media worden gebruikt.
            </p>
          </div>
          <el-upload
            ref="upload"
            action=""
            class="image-uploader"
            :on-change="handleChange"
            :show-file-list="false"
            :auto-upload="false"
          >
            <img v-if="imageUrl" :src="imageUrl" class="image" />
            <i v-else class="el-icon-picture image-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-button
          type="primary"
          @click="submitRequest"
          :disabled="isSubmitting"
          >Verstuur</el-button
        >
      </el-form>
    </el-card>

    <!-- Just requested -->
    <el-card
      v-else-if="justRequested"
      class="card card--small"
      style="text-align: right"
    >
      <h1 class="card__heading text-center">Bedankt voor je verzoekje!</h1>
      <p class="card__description text-center">
        Wie weet wordt jouw verzoeknummer zometeen gespeeld. Hou onze schermen
        in de gaten!
      </p>

      <el-button
        type="primary"
        @click="userCanSubmit(), (justRequested = false)"
        >Afsluiten</el-button
      >
    </el-card>

    <!-- Too many requests -->
    <el-card v-else class="card card--small">
      <h1 class="card__heading text-center">Helaas!</h1>

      <p class="card__description text-center">
        Je kunt drie keer per kwartier een verzoekje indienen. Even wachten
        dus...
      </p>
      <el-progress
        type="circle"
        :percentage="requestLimit.timePercentage"
        style="transform: translateX(-50%); left: 50%"
      >
        <template #default>
          <p>
            {{ formattedTimeLeft }}
          </p>
        </template>
      </el-progress>
    </el-card>
  </div>
</template>

<script>
import fetchMixins from "../mixins/ajax";
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElUpload,
  ElCheckbox,
  ElProgress,
} from "element-plus";
export default {
  components: {
    ElForm,
    ElFormItem,
    ElInput,
    ElUpload,
    ElCheckbox,
    ElProgress,
  },
  mixins: [fetchMixins],
  data() {
    return {
      form: {
        title: "",
        artist: "",
        firstName: "",
        lastName: "",
        karaoke: false,
        image: null,
      },
      formRules: {
        title: [
          {
            required: true,
            message: "Vul de titel van het nummer in",
            trigger: "change",
          },
          { max: 30, message: "Gebruik maximaal 30 tekens", trigger: "change" },
        ],
        artist: [
          {
            required: true,
            message: "Vul de naam van de artiest in",
            trigger: "change",
          },
          { max: 30, message: "Gebruik maximaal 30 tekens", trigger: "change" },
        ],
        firstName: [
          { required: true, message: "Vul je voornaam in", trigger: "change" },
          { max: 12, message: "Gebruik maximaal 12 tekens", trigger: "change" },
        ],
        lastName: [
          {
            required: true,
            message: "Vul je achternaam in",
            trigger: "change",
          },
          { max: 12, message: "Gebruik maximaal 12 tekens", trigger: "change" },
        ],
      },
      imageUrl: null,
      justRequested: false,
      allowedToSubmit: true,
      isSubmitting: false,
      requestLimit: {
        timePercentage: 0,
        secondsLeft: 0,
        minutesLeft: 15,
      },
      intervalInstance: null,
    };
  },
  computed: {
    lessThenAMinuteLeft() {
      return Math.round(15 - (this.timePercentage / 100) * 15) < 1;
    },
    formattedTimeLeft() {
      let minutes = this.requestLimit.minutesLeft.toString();
      minutes = minutes.length < 2 ? `0${minutes}` : minutes;

      let seconds = this.requestLimit.secondsLeft.toString();
      seconds = seconds.length < 2 ? `0${seconds}` : seconds;

      return `${minutes}:${seconds}`;
    },
  },
  methods: {
    handleChange(file) {
      // Validate file type and size
      const isImage = file.raw.type.includes("image/");
      const hasAcceptedSize = file.size / 1024 / 1024 < 6;

      if (!isImage || !hasAcceptedSize) {
        this.$message({
          message: "Kies een foto van minder dan 6 MB",
          duration: 5000,
          customClass: "",
          showClose: true,
        });
        return;
      }

      this.imageUrl = URL.createObjectURL(file.raw);
      this.form.image = file.raw;
      this.$refs.upload.clearFiles();
    },
    submitRequest() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (!this.userCanSubmit()) {
            return;
          }

          this.isSubmitting = true;

          const payload = new FormData();
          payload.append("artist", this.form.artist);
          payload.append("song", this.form.title);
          payload.append("requester_firstname", this.form.firstName);
          payload.append("requester_lastname", this.form.lastName);
          payload.append("karaoke", this.form.karaoke);

          if (this.form.image !== null) {
            payload.append("image", this.form.image);
          }

          const response = await this.fetchData(
            null,
            "request/create",
            "POST",
            null,
            payload
          );

          if (response.statusCode !== 200) {
            this.$message(`Er is iets fout gegaan. ${response.error.message}`);
            return;
          }

          this.justRequested = true;
          this.isSubmitting = false;
          window.scrollTo(0, 0);

          // Store submit date in local storage and check that the user is still allowed to submit
          this.setSubmitToLocalStorage(response.data.createdAt);
          this.userCanSubmit();

          this.$refs["form"].resetFields();
          this.form.karaoke = false;
          this.imageUrl = null;
          this.form.image = null;
        }
      });
    },
    async fetchActiveVote() {
      const response = await this.fetchData(
        null,
        "vote/active",
        "GET",
        null,
        null
      );

      if (response.statusCode === 200) {
        const VoteIsValid =
          new Date(response.data.start_time).getTime() +
            response.data.duration >
          Date.now();
        if (
          VoteIsValid &&
          localStorage.getItem("vote") !== response.data.start_time.toString()
        ) {
          this.$store.commit("setActiveVote", response.data);
          this.$router.push({
            name: "Vote",
            params: { allowedToVote: true },
          });
        }
      } else {
        localStorage.removeItem("vote");
      }
    },
    getSubmitsFromLocalStorage() {
      const submits = localStorage.getItem("submits");
      if (submits) {
        return JSON.parse(submits);
      } else {
        const newSubmitsObj = [];
        localStorage.setItem("submits", newSubmitsObj);
        return newSubmitsObj;
      }
    },
    setSubmitToLocalStorage(submit) {
      const submits = this.getSubmitsFromLocalStorage();

      submits.push(submit);

      localStorage.setItem("submits", JSON.stringify(submits));
    },
    userCanSubmit() {
      const submits = this.getSubmitsFromLocalStorage();

      const now = new Date();
      submits.forEach((value, index, array) => {
        if (now - new Date(value) > 1000 * 60 * 15) {
          array.splice(index, 1);
        }
      });

      localStorage.setItem("submits", JSON.stringify(submits));

      // Return true if user is allowed to submit, false otherwise
      this.allowedToSubmit = submits.length < 3;
      return this.allowedToSubmit;
    },
    startCountdownTimer() {
      const firstSubmit = this.getSubmitsFromLocalStorage()[0];
      const endTime = new Date(
        new Date(firstSubmit).getTime() + 1000 * 60 * 15
      );

      const calculateTime = () => {
        const timePercentage = Math.floor(
          ((new Date() - new Date(firstSubmit)) / (1000 * 60 * 15)) * 100
        );
        const timeLeft = endTime - Date.now();

        this.requestLimit.timePercentage =
          timePercentage <= 100 ? timePercentage : 100;
        this.requestLimit.minutesLeft = Math.floor(timeLeft / (60 * 1000));
        this.requestLimit.secondsLeft = Math.floor(timeLeft / 1000) % 60;
      };

      // initial calculation
      calculateTime();

      this.intervalInstance = setInterval(() => {
        if (this.requestLimit.timePercentage >= 100) {
          clearInterval(this.intervalInstance);
          this.allowedToSubmit = true;

          // Reset to initial values
          this.requestLimit.timePercentage = 0;
          this.requestLimit.secondsLeft = 0;
          this.requestLimit.minutesLeft = 15;
        } else {
          calculateTime();
        }
      }, 1000);
    },
  },
  watch: {
    allowedToSubmit(newVal) {
      if (newVal === false) {
        this.startCountdownTimer();
      }
    },
  },
  created() {
    this.userCanSubmit();
    this.fetchActiveVote();
  },
};
</script>

<style lang="scss" scoped>
.image-uploader ::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.image-uploader ::v-deep .el-upload:hover {
  border-color: $color-primary;
}
.image-uploader-icon {
  font-size: 6.5rem;
  color: #8c939d;
  width: 10rem;
  height: 10rem;
  line-height: 10rem;
  text-align: center;
}
.image {
  width: 10rem;
  height: 10rem;
  object-fit: contain;
  display: block;
  background-color: #303133;
}

.form-extras {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.info-btn {
  margin-bottom: 3rem;
}

::v-deep .el-form-item {
  &__label {
    font-weight: 600;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    line-height: 2;
  }

  &__error {
    line-height: 1;
    top: calc(100% + 2px);
    left: 2px;
  }
}

.bg-image {
  position: fixed;
  left: 0;
  top: 6.5rem;
  width: 100%;
  height: 100%;
  z-index: -1;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.08;
    filter: grayscale(1);
  }
}

.label-subtitle {
  color: $color-light-gray;
  margin-top: -10px;
  line-height: 1.4;
}
</style>
